.centro-loading-stencil {
  position: relative;
}

.centro-loading-stencil .centro-position-absolute {
  position: absolute;
  top: 20px;
  padding: 1rem;
  z-index: 1;
}

.centro-position-absolute {
  text-shadow: 0 0 7px black;
  color: transparent;
  animation: 400ms ease-in-out 0s infinite alternate pulse;
}

@keyframes pulse {
  0% {
    opacity:0.6;
  }
  100% {
    opacity: 1
  }
}