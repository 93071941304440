.centro-profile-window {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  font-family: "upgrade-lights";
  font-weight: 500;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  overflow: auto;
}

.centro-payment-info {
  padding: 1rem;
  background-color: #fff;
  margin: auto;
  margin-top: 2rem;
  display:flex;
  flex-direction:column;
  text-align: center;
  min-width: 45vw;
}

.centro-payment-info .stripe-logo {
  max-height: 30px;
}

.centro-payment-unavailable {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centro-payment-info a {
  font-weight: bold;
  text-decoration: underline;
}

.centro-payment-info button {
  float: right;
  cursor: pointer;
  text-decoration: none;
  color: #fafafa;
  padding: 8px 14px 9px;
  font-size: 15px;
  vertical-align: bottom;
  border-radius: .25rem;
  background: #eee;
  background-color: #0E6948;
  border: 2px solid #0E6948;
  font-weight: bold;
  color: #fff;
  white-space: nowrap;
  position: relative;
  line-height: 1.2rem;
  text-shadow: 0 1px 1px rgba(0,0,0,.1);
  text-align: center;
  direction: rtl;
}

.centro-payment-info button:disabled {
  background-color: #ccc;
  border: 2px solid #ccc;
}

.centro-payment-section { 
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.centro-button-section { 
  display: flex;
  justify-content: space-between;
  margin-top: 0.25rem;
  flex-wrap: wrap;
}

.centro-payment-info button .slds-icon {
  fill: #fff;
  padding-right: 0.25rem;
}

.centro-input-users {
  width: 4em;
}

.centro-payment-section {
  text-align: left;
}

.centro-summary {
  text-align: left;
  flex: 1 0 45%;
}

.centro-payment-info input, select {
  font-weight: bold;
}

.centro-align-center {
  margin:auto;
}

.centro-submit {
  align-self: center;
  flex: 1 1 auto;
}

.centro-truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}