.slds-button_icon {
  line-height: 1;
  vertical-align: middle;
  justify-content: center;
  color: #706e6b;
  flex-shrink: 0;
}

.slds-button {
  text-decoration: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0;
  background: transparent;
  background-clip: border-box;
  border: 1px solid transparent;
  border-radius: .25rem;
  line-height: 1.875rem;
  color: #0070d2;
  -webkit-appearance: none;
  white-space: normal;
  user-select: none;
  flex-direction: row-reverse;
}