.centro-payment-method {
  display: flex;
  flex-direction: column;
  /* border: 1px solid #999; */
  /* background-color:#eee; */
  border-radius: 5px;
  text-align: left;
}

.centro-payment-method .centro-flex-horizontal {
  align-items: center;
}

.centro-payment-method h4 {
  margin: auto;
}

.centro-payment-method .centro-expired {
  color: #c23934;
  font-weight: bold;
}

.centro-payment-method .centro-flex-horizontal {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 0.25rem;
}

.centro-payment-method .centro-flex-horizontal div {
  margin-right: 0.75rem;
  flex: 1 1 auto;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.centro-payment-method .centro-card-brand {
  text-transform: capitalize;
}

.centro-payment-method .centro-remove-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  color: rgba(var(--sk_highlight,18,100,163),1);
}

.centro-payment-method .centro-remove-butto:hover,
.centro-payment-method .centro-remove-butto:focus {
  text-decoration: none;
}