.centro-404-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  font-family: 'Open Sans', Arial, sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}
.centro-404-container .center {
  margin: auto;
  display:flex;
  flex-direction:column;
  text-align: center;
  padding: 1rem;
}

.centro-404-container .centro-button-link {
  text-align: center;
  justify-content: center;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  box-shadow: rgb(115, 147, 15) 0px 0px 0px 3px inset;
  color: rgb(115, 147, 15);
  padding: 11px 24px;
  border-radius: 48px;
  border-width: 0px;
  outline: 0px;
  text-decoration: none;
  background: transparent;
  white-space: nowrap;
}

navigation-header a {
  color: white;
}