.centro-menu {
  display: inline-block;
  position: relative;
  font-weight: normal;
  font-size: 85%;
  cursor: default;
}

.centro-menu .centro-menu-items {
  transition: all 300ms;
  max-height: 0px;
  max-width: 0px;
  overflow: hidden;
  white-space: nowrap;
  background-color: white;
  box-shadow: 1px 1px 3px #999;
  border-radius: 5px;
  position: absolute;
  z-index: 999;
}

.centro-menu.centro-is-open .centro-menu-button {
  /* Show button on top of menu only when in open state */
  z-index: 999;
  position: relative;
}

.centro-menu.centro-is-open .centro-menu-items {
  /* These should be as close as possible to actual open size for best animation effect */
  max-height: 560px;
  max-width: 175px;
}

.centro-menu .centro-menu-items > li {
  white-space: nowrap;
  line-height: 1.5rem;
}