html, body, #root {
  position: absolute;
  top:0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
}

.centro-flex-vertical {
	height: 100%;
  min-height: 100%;
	display: flex;
  flex-direction: column;
}

.centro-flex-horizontal-reverse {
  display: flex;
  flex-direction: row-reverse;
}
	
.centro-button-bar {
  margin: 0.5rem;
}

.centro-input {
  flex: 1;
}

.centro-button-bar label {
  font-size: .75rem;
  padding-right: .5rem;
  padding-top: .25rem;
  margin-bottom: .125rem;
}

.centro-button-bar input {
  border: 1px solid #dddbda;
  border-radius: .25rem;
  width: 100%;
  display: inline-block;
  line-height: 1.75rem;
  padding: 0 1rem 0 0.75rem;
}

.centro-button.centro-button-primary {
  background-color: rgb(0,122,90);
  justify-content: center;
  border: 2px solid rgb(0,122,90);
  font-weight: 900;
  font-size: 15px;
  color: #fff;
  min-width: 80px;
}

.centro-button .slds-icon {
  fill: #333;
  padding-left: 0.25rem;
}

.centro-button {
  /* display: inline-block; */
  cursor: pointer;
  text-decoration: none;
  color: #fafafa;
  -webkit-font-smoothing: antialiased;
  padding: 8px 14px 9px;
  margin-left: 0.25rem;
  font-size: 15px;
  vertical-align: bottom;
  border-radius: .25rem;
  background: #fff;
  border: 2px solid #3aa3e3;
  color: black;
  white-space: nowrap;
  position: relative;
  line-height: 1.2rem;
  text-shadow: 0 1px 1px rgba(0,0,0,.1);
  text-align: center;
  max-width: 200px;
  direction: ltr;
}

.centro-editor {
  flex: 1;
}