.centro-merge-menu li {
  list-style: none;
}

.centro-merge-menu h2 {
  margin: 0;
}

.centro-menu {
  direction: rtl;
}

.centro-merge-menu .centro-menu-items {
  direction: ltr;
  margin: 1.25rem;
}

.centro-merge-menu .centro-menu-items div {
  padding: 2rem;
  padding-top: 2.25rem;
  padding-bottom: 1rem;
}

.centro-merge-menu ul {
  padding-left: 0.5rem;
  line-height: 1.5rem;
  margin: 0.5rem;
}

.centro-merge-menu li button {
  background: none;
  border: none;
  padding: 0;
  font-size: 125%;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}